import { Chevron } from 'DesignSystem/Icons';
import AnimatedRotateIcon from 'DesignSystem/Icons/ExtenderIcons/AnimatedRotateIcon';
import { styled } from 'Theme/stitches.config';
export type MobileMenu = {
  text: string;
  onClick?: () => void;
};
function BackLink({ text, onClick }: MobileMenu) {
  return (
    <Root onClick={onClick}>
      <AnimatedRotateIcon direction="90">
        <Chevron size="m" color={'primary'} />
      </AnimatedRotateIcon>
      {text}
    </Root>
  );
}

const Root = styled('button', {
  alignItems: 'center',
  color: '$onSurface',
  cursor: 'pointer',
  display: 'flex',
  fontFamily: 'fontSemiBold',
  fs: 6,
  g: 8,
  lineHeight: '$lh16',
  position: 'relative',
  p: '$s100',
  w: '100%',
});
export default BackLink;
