import { Chevron } from 'DesignSystem/Icons';
import AnimatedRotateIcon from 'DesignSystem/Icons/ExtenderIcons/AnimatedRotateIcon';
import HeaderLinkModel from 'Models/Headers/HeaderLinkModel.interface';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import { StitchesVariantProps } from 'Shared/Types/types';
import { styled } from 'Theme/stitches.config';
import { animationOpacity } from 'DesignSystem/Animations/keyframes';

type MenuStyle = StitchesVariantProps<typeof Item>;

export type MobileMenu = {
  item: HeaderLinkModel;
  type?: MenuStyle['type'];
  hasAnimation?: boolean;
  onClick?: () => void;
};

type ItemProps = {
  item: HeaderLinkModel;
};

function MenuItem({
  item,
  type = 'regular',
  hasAnimation,
  onClick,
}: MobileMenu) {
  const hasSubLinks = item?.subLinks?.length > 0;

  const renderItemContent = (item: HeaderLinkModel) => {
    return (
      <>
        <Item type={type} active={item.active}>
          {item?.dropdownHeading ??
            item?.dropdownLink?.text ??
            item?.heading ??
            item?.text}
        </Item>
      </>
    );
  };

  const Link = ({ item }: ItemProps) => {
    return (
      <InteractableContentLink href={item?.dropdownLink?.href ?? item.href}>
        {renderItemContent(item)}
      </InteractableContentLink>
    );
  };

  return (
    <Root hasAnimation={hasAnimation}>
      {hasSubLinks ? (
        !item?.heading && !item?.text && !item?.dropdownHeading ? (
          item.subLinks.map((subLink: HeaderLinkModel, index: number) => (
            <Wrapper key={index}>
              <Link item={subLink} />
            </Wrapper>
          ))
        ) : (
          <Wrapper>
            <StyledButton onClick={onClick}>
              {renderItemContent(item)}
              <AnimatedRotateIcon direction={'270'}>
                <Chevron size="m" color={'primary'} />
              </AnimatedRotateIcon>
            </StyledButton>
          </Wrapper>
        )
      ) : (
        <Wrapper>
          <Link item={item} />
        </Wrapper>
      )}
    </Root>
  );
}

const Root = styled('div', {
  cursor: 'pointer',
  variants: {
    hasAnimation: {
      true: {
        animation: `${animationOpacity} 0.7s`,
      },
    },
  },
});

const Wrapper = styled('div', {
  display: 'flex',
  position: 'relative',
  w: '100%',
  px: '$s100',
  a: {
    display: 'flex',
    alignItems: 'center',
    g: 16,
    lineHeight: '$lh24',
    '@bpMax720': {
      w: '100%',
    },
  },
});

const Item = styled('span', {
  display: 'flex',
  justifyContent: 'space-between',
  py: '$s100',
  w: '100%',
  color: '$inverse',
  variants: {
    type: {
      regular: {
        fs: 12,
        fontFamily: 'fontSemiBold',
      },
      secondary: {
        fontSize: '$fontSize75',
      },
    },
    active: {
      true: {
        '&:before': {
          position: 'absolute',
          display: 'block',
          content: ' ',
          w: 10,
          backgroundColor: '$interactiveBorderActive',
          h: 4,
          l: '3px',
          my: 'auto',
          t: 'calc(50% - 2px)',
        },
      },
    },
  },
});

const StyledButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: 1,
  g: '$s100',
  lineHeight: '$lh24',
});

export default MenuItem;
